import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { setClassNames } from 'ddc-classnames-js';

const SpotIcon = ({
	spotIconName,
	spotIconSize,
	spotIconMargin,
	spotIconPadding,
	spotIconAlignment
}) => {
	return (
		<SVG
			src={`/static/assets/icons/spot/${spotIconName}.svg`}
			aria-hidden="true"
			height={spotIconSize}
			width={spotIconSize}
			loader={
				<div
					style={{
						width: `${spotIconSize}px`,
						height: `${spotIconSize}px`
					}}
				/>
			}
			className={setClassNames(
				spotIconMargin,
				spotIconPadding,
				spotIconAlignment
			)}
		/>
	);
};

SpotIcon.defaultProps = {
	spotIconMargin: '',
	spotIconPadding: '',
	spotIconAlignment: ''
};

SpotIcon.propTypes = {
	spotIconName: PropTypes.string.isRequired,
	spotIconSize: PropTypes.string.isRequired,
	spotIconMargin: PropTypes.string,
	spotIconPadding: PropTypes.string,
	spotIconAlignment: PropTypes.string
};

export default SpotIcon;
