import React from 'react';
import PropTypes from 'prop-types';
import { Common } from 'ws-scripts/modules/common'; // eslint-disable-line import/no-unresolved

class WidgetHeading extends React.Component {
	constructor(props) {
		super(props);
		this.renderDelegate = this.renderDelegate.bind(this);
	}

	renderDelegate({ labels }) {
		const {
			HeadingTag,
			headingText,
			widgetId,
			toggleCollapse,
			showIcon,
			headingClasses,
			iconClasses
		} = this.props;

		const allIconClasses = [...iconClasses];
		let collapseAttributes = {};

		if (toggleCollapse) {
			const iconExpand = 'ddc-icon-expand';

			allIconClasses.push(iconExpand);
			allIconClasses.push('icon-style-collapse');

			collapseAttributes = {
				role: 'button',
				'data-toggle': 'collapse',
				'data-target': `#${widgetId}-collapse`,
				'data-expand-icon-class': `${iconExpand}`,
				'data-collapse-icon-class': 'ddc-icon-collapse',
				'aria-expanded': 'false',
				'aria-controls': `${widgetId}-collapse`
			};
		}

		return (
			<HeadingTag
				className={headingClasses.join(' ')}
				{...collapseAttributes}
			>
				{showIcon && (
					<React.Fragment>
						<i className={allIconClasses.join(' ')} />{' '}
					</React.Fragment>
				)}
				{labels.getHTML(headingText)}
			</HeadingTag>
		);
	}

	render() {
		if (
			!this.props.headingText ||
			this.props.headingText.toUpperCase() === 'FALSE'
		) {
			return null;
		} else {
			return <Common render={this.renderDelegate} />;
		}
	}
}

WidgetHeading.propTypes = {
	HeadingTag: PropTypes.string,
	headingText: PropTypes.string,
	widgetId: PropTypes.string.isRequired,
	toggleCollapse: PropTypes.bool.isRequired,
	showIcon: PropTypes.bool.isRequired,
	headingClasses: PropTypes.arrayOf(PropTypes.string).isRequired,
	iconClasses: PropTypes.arrayOf(PropTypes.string).isRequired
};

WidgetHeading.defaultProps = {
	HeadingTag: 'h3',
	headingText: ''
};

export default WidgetHeading;
