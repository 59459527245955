// This file is intended for global constants that are shared across
// the application. Use this file sparingly. For constants with a single
// usage, it can be better to include them in the module scope of the
// file they're used in.
export const INV_FILTERS_WIDGET = 'ws-inv-filters';
export const SHOW_PAYMENTS_NOTIFICATION = `${INV_FILTERS_WIDGET}/show-payments-notification`;
export const SHOW_PAYMENTS_LOCAL_STORAGE_KEY = 'userShowPayments';

export const highlightedAttrList = {
	FUEL_TYPE: 'normalFuelType',
	ESTIMATED_DELIVERY_DATE: 'estimatedDeliveryDate',
	LOCATION: 'accountName',
	ODOMETER: 'odometer',
	CERTIFIED: 'certified',
	CONDITION: 'type',
	TRANSFER: 'sharedVehicle',
	ONSITE: 'onSiteVehicle',
	CLASSIFICATION: 'classificationName'
};

export const inventoryPageSizes = {
	SM_PAGE_SIZE: 4,
	MD_PAGE_SIZE: 10,
	LG_PAGE_SIZE: 14,
	XL_PAGE_SIZE: 18
};

export const MIN_COLUMNS_FOR_POSITIONING = 2;
export const DEFAULT_MAX_COLUMNS_ON_SRP = 4;
export const MAX_NUM_HYUNDAI_ADS = 3;
export const MIN_NUM_HYUNDAI_ADS = 2;

// Contact params to select Dealer Name, Address, Phone numbers from wsm-contact-transformer
export const CONTACT_OPTIONS = {
	phoneType: 'Sales', // Display Sales phone as default
	deptPhoneType: 'Sales, Service, Parts', // Display department phones if it has
	displayPhone: true,
	displaySiteUrl: false
};
