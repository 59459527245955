export default function prefs(state = {}, action = null) {
	if (!action.error && action.payload !== undefined) {
		switch (action.type) {
			case 'WS_SCRIPTS_SET_PREFS':
				return action.payload;

			// no default
		}
	}

	return state;
}
