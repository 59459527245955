import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useRequestData, useLabels } from 'wsm-common-data';
import { trackEvent } from 'ddc-track-event';
import { setClassNames } from 'ddc-classnames-js';
import { useCompareLink } from '../../../../hooks/useCompareLink';

const CompareContent = ({ selected, isInline }) => {
	const labels = useLabels();
	const { compareVehicles } = useSelector((state) => state.compareVehicles);
	const { widgetName, windowId } = useRequestData();

	const compareLink = useCompareLink();

	const commonClasses = [
		'ddc-font-size-small text-muted',
		isInline ? 'ml-3' : ''
	];

	if (compareVehicles.length >= 2 && selected) {
		const compareXVehicles = labels
			.get('COMPARE_X_VEHICLES')
			.replace('{count}', compareVehicles.length);
		return (
			<a
				className={setClassNames(commonClasses)}
				href={compareLink}
				onClick={() => {
					trackEvent(widgetName, windowId, {
						element: 'Compare link',
						result: 'Navigated to comparison page'
					});
				}}
			>
				{compareXVehicles}
			</a>
		);
	} else if (compareVehicles.length === 1 && selected) {
		return (
			<span className={setClassNames(commonClasses)}>
				{labels.get('ADD_MORE_TO_COMPARE')}
			</span>
		);
	} else {
		return null;
	}
};

CompareContent.propTypes = {
	selected: PropTypes.bool.isRequired,
	isInline: PropTypes.bool.isRequired
};

export default CompareContent;
