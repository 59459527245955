/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const transformAccountData = (data) => {
	if (typeof data !== 'object' || Object.keys(data).length < 1) {
		return {};
	}

	const accounts = Object.keys(data);
	return accounts.reduce((obj, account) => {
		obj[account] = {
			name: data[account].name,
			accountId: account,
			webAddress: data[account].url,
			address: data[account].address,
			dealerCodes: data[account].dealerCodes
		};
		return obj;
	}, {});
};

const initialState = {
	accounts: {}
};

export const accountDataSlice = createSlice({
	name: 'accountData',
	initialState,
	reducers: {
		setAccountData(state, action) {
			state.accounts = transformAccountData(action.payload);
		}
	}
});

export const { setAccountData } = accountDataSlice.actions;

export default accountDataSlice.reducer;
