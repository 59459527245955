export const hasInTransitBadge = (callout) => {
	if (!callout) {
		return false;
	}
	return callout.some(
		(item) =>
			item.badgeClasses.includes('in-transit') ||
			item.badgeClasses.includes('in-transit-at-factory')
	);
};
