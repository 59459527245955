/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';
import { useRequestData, useLabels } from 'wsm-common-data';
import { removeFilter } from '../../utilities/filters';

const FilterItem = ({
	filterId,
	filterLabel,
	value,
	label,
	showRemoveButton
}) => {
	const labels = useLabels();
	const { widgetName, windowId } = useRequestData();
	let newLabel = null;

	if (
		filterId === 'normalInteriorColor' ||
		filterId === 'normalExteriorColor'
	) {
		newLabel = `${filterLabel}: ${value}`;
	}

	return (
		<button
			type="button"
			className="pill chip chip-default chip-condensed facet-filter"
			onClick={(e) =>
				showRemoveButton
					? removeFilter({
							e,
							id: filterId,
							value,
							widgetName,
							windowId
					  })
					: {}
			}
			aria-label={labels
				.get('FILTER_VALUE_CLICK_TO_REMOVE')
				.replace('{filter}', filterLabel)
				.replace('{value}', value)}
		>
			<span dangerouslySetInnerHTML={{ __html: newLabel || label }} />{' '}
			{showRemoveButton ? (
				<i className="ddc-icon ddc-icon-remove2" />
			) : null}
		</button>
	);
};

FilterItem.propTypes = {
	filterId: PropTypes.string.isRequired,
	filterLabel: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	showRemoveButton: PropTypes.bool
};

FilterItem.defaultProps = {
	showRemoveButton: true
};

export default FilterItem;
