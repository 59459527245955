import { isBrowser } from 'ws-scripts/modules/environment';

export const getFlagValue = (flags, flagKey, overrideKey) => {
	let urlParams = {};
	if (isBrowser && typeof window.DDC?.getUrlParams === 'function') {
		urlParams = window.DDC.getUrlParams();
	}

	const finalizedValue = urlParams[overrideKey]
		? urlParams[overrideKey]
		: flags[flagKey];

	if (['true', 'false'].includes(finalizedValue)) {
		return finalizedValue === 'true';
	}

	return finalizedValue;
};
