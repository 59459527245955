import React from 'react';
import PropTypes from 'prop-types';
import Alert from './Alert';

const ErrorAlert = ({ errorLabel }) => {
	return (
		<Alert
			alertIconClass="ddc-icon-alert"
			alertVariantClass="alert-warning"
		>
			<span>{errorLabel}</span>
		</Alert>
	);
};

ErrorAlert.propTypes = {
	errorLabel: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.element)
	]).isRequired
};

export default ErrorAlert;
