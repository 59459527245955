/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	franchiseList: []
};

export const franchiseListSlice = createSlice({
	name: 'franchiseList',
	initialState,
	reducers: {
		setFranchiseList(state, action) {
			state.franchiseList = action.payload;
		}
	}
});

export const { setFranchiseList } = franchiseListSlice.actions;

export default franchiseListSlice.reducer;
