import { isBrowser } from 'ws-scripts/modules/environment';

export const useMyCarsSiteProperties = () => {
	const enableMyCars =
		isBrowser &&
		Boolean(window?.DDC?.siteProperties?.enableMyCars === 'true');

	return {
		enableMyCars
	};
};
