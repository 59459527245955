/**
 * Extracts the number of miles from a distance string formatted as '{number}mi' or '{number}km'.
 *
 * @param {string} distanceString - The distance string to extract miles from, which can be in miles (mi) or kilometers (km).
 * @returns {number|null} - The extracted number of miles as an integer, or null if the input is invalid or the format is incorrect.
 */

export const extractMiles = (distanceString) => {
	if (typeof distanceString !== 'string') return null;
	const match = distanceString.match(/(\d+)(mi|km)/);
	if (match) {
		const number = parseInt(match[1], 10);
		const unit = match[2];
		if (unit === 'mi') {
			return number;
		} else if (unit === 'km') {
			return Math.floor(number * 0.621371);
		}
	}
	return null;
};
