import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { usePrefs } from 'ws-scripts/modules/common';

const VehicleAttributeItem = ({ detailName, detailValue, swatchColor }) => {
	const prefs = usePrefs();
	const isComment = detailName === 'comments';
	const isLocationDistance = detailName === 'locationDistance';
	const liClassNames = setClassNames([
		swatchColor ? 'normalized-swatch-container' : null,
		detailName
	]);

	const getAttributeItemMarkup = () => {
		if (isLocationDistance) {
			return <></>;
		} else if (!isComment && !swatchColor) {
			return (
				<li
					key={detailName}
					className={liClassNames}
					dangerouslySetInnerHTML={{ __html: detailValue }}
				/>
			);
		} else {
			return (
				<li key={detailName} className={liClassNames}>
					{swatchColor && (
						<span
							className={setClassNames([
								'normalized-swatch',
								`normalized-swatch-${swatchColor}`
							])}
						/>
					)}
					{isComment ? (
						/* eslint react/no-danger: 0 */
						/* comments need nested span for line-clamp css to work with
				multiple paragraphs in firefox */
						<span
							className={`line-clamp-${prefs.commentsTruncateLines} text-break`}
						>
							<span
								dangerouslySetInnerHTML={{
									__html: detailValue
								}}
							/>
						</span>
					) : (
						detailValue
					)}
				</li>
			);
		}
	};
	return getAttributeItemMarkup();
};

VehicleAttributeItem.propTypes = {
	detailName: PropTypes.string.isRequired,
	detailValue: PropTypes.string.isRequired,
	swatchColor: PropTypes.string
};

VehicleAttributeItem.defaultProps = {
	swatchColor: null
};

export default VehicleAttributeItem;
