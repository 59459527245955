import React, { lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { trackEvent } from 'ddc-track-event';
import { Button } from 'react-bootstrap';
import { setClassNames } from 'ddc-classnames-js';
import Skeleton from 'wsm-skeleton';
import {
	useLabels,
	usePrefs,
	useRequestData,
	useFlags
} from 'ws-scripts/modules/common';
import { addImageParams } from '../../../utilities/images';
import { setModalSettings } from '../../../features/modalSettings';
import {
	getTargetLocations,
	getVehicleLocationName
} from '../../../utilities/getTargetLocations';
import VehicleImage from './VehicleImage';
import Callout from '../Callout';
import './Slick.scss';
import './MediaContainer.scss';
import { selectIsTabbedLayout } from '../../../utilities/layout';

const LazyVehicleImageCarousel = lazy(() => import('./VehicleImageCarousel'));

const Media = ({
	enableMediaCarousel,
	vehicle,
	accountPhone,
	loadingDelayed,
	usePlaceholderImage,
	willRenderBlankPlaceholderImage,
	hasCarouselImages
}) => {
	const dispatch = useDispatch();
	const flags = useFlags();
	const labels = useLabels();
	const { deviceType, widgetName, windowId } = useRequestData();
	const {
		showVideoModalButton,
		openVideoModalLabel,
		restrictInsertLocationsForVehicles
	} = usePrefs();
	const isTabbedLayout = useSelector((state) => selectIsTabbedLayout(state));
	const targetLocations = getTargetLocations(
		vehicle,
		restrictInsertLocationsForVehicles
	);

	const vehicleMediaLocation = getVehicleLocationName(
		'vehicle-media',
		targetLocations,
		restrictInsertLocationsForVehicles
	);

	const { videos = [], uuid, title, link, images = [] } = vehicle;

	const phone = accountPhone;

	const enableRepositionCarouselIndicatorDots =
		flags['enable-reposition-carousel-indicator-dots'];

	let videoButton = null;
	const isShowVideoModalButton =
		showVideoModalButton === 'true' && videos.length > 0;
	if (isShowVideoModalButton) {
		videoButton = (
			<Button
				bsSize={deviceType === 'DESKTOP' ? 'xsmall' : null}
				className={setClassNames([
					'open-media-modal-btn',
					deviceType === 'MOBILE' ? 'text-nowrap' : '',
					enableRepositionCarouselIndicatorDots &&
					deviceType === 'DESKTOP'
						? 'd-block float-right'
						: ''
				])}
				onClick={() => {
					trackEvent(widgetName, windowId, {
						element: 'Video button',
						result: 'Video modal displayed'
					});

					dispatch(
						setModalSettings({
							isModalOpen: true,
							videos,
							uuid,
							title,
							link,
							phone
						})
					);
				}}
			>
				<i className="ddc-icon ddc-icon-play2 mr-2" />
				<span className="text-link text-link-muted">
					{labels.get(openVideoModalLabel)}
				</span>
			</Button>
		);
	}

	// Return false if _toggleMediaCarousel='false' added into URL param
	const showCarousel = flags['srp-use-media-carousel'] && enableMediaCarousel;
	const placeholderImageSrc =
		usePlaceholderImage && images[0]?.uri
			? addImageParams(images[0].uri)
			: null;

	// add container styling for if carousel is enabled and we have at least one vehicle with multiple images
	// Update: Moved carousel indicator dots over the vehicle image.
	const addCarouselContainerStyle =
		showCarousel &&
		hasCarouselImages &&
		!enableRepositionCarouselIndicatorDots
			? 'vehicle-card-media-container-carousel'
			: 'vehicle-card-media-container-no-carousel';

	const vehicleImage = (
		<div className={addCarouselContainerStyle}>
			<VehicleImage
				image={images[0]}
				imageIndex={0}
				link={vehicle.link}
				uuid={vehicle.uuid}
				isLoaded={willRenderBlankPlaceholderImage}
			/>
		</div>
	);
	let vehicleMedia = vehicleImage;

	if (!loadingDelayed) {
		// if content is a carousel with more than 1 image and useMediaCarousel FF is true
		if (showCarousel && images.length > 1) {
			vehicleMedia = (
				<div
					className={setClassNames([
						isShowVideoModalButton &&
						enableRepositionCarouselIndicatorDots
							? 'mb-0'
							: addCarouselContainerStyle
					])}
				>
					<Suspense fallback={vehicleImage}>
						<Skeleton
							skeletonClasses="vehicle-image-skeleton embed-responsive"
							placeholderImage={placeholderImageSrc}
						/>
						<LazyVehicleImageCarousel
							images={images}
							isShowVideoModalButton={isShowVideoModalButton}
							link={vehicle.link}
							uuid={vehicle.uuid}
							videoButton={videoButton}
						/>
					</Suspense>
				</div>
			);
		}
	}
	return (
		<>
			{vehicleMedia}
			{videoButton}
			{isTabbedLayout && (
				<ul
					className="list-unstyled d-flex flex-column vehicle-card-media align-items-center hidden"
					data-location={vehicleMediaLocation}
				/>
			)}
			{!isTabbedLayout && (
				<Callout callouts={vehicle.callout} vehicle={vehicle} />
			)}
		</>
	);
};

Media.propTypes = {
	enableMediaCarousel: PropTypes.bool.isRequired,
	hasCarouselImages: PropTypes.bool.isRequired,
	vehicle: PropTypes.shape({
		images: PropTypes.arrayOf(PropTypes.shape({})),
		uuid: PropTypes.string,
		link: PropTypes.string,
		title: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
		phone: PropTypes.string,
		callout: PropTypes.arrayOf(PropTypes.shape({})),
		videos: PropTypes.arrayOf(PropTypes.shape({}))
	}).isRequired,
	accountPhone: PropTypes.string,
	loadingDelayed: PropTypes.bool,
	usePlaceholderImage: PropTypes.bool,
	willRenderBlankPlaceholderImage: PropTypes.bool.isRequired
};

Media.defaultProps = {
	loadingDelayed: false,
	accountPhone: ''
};

export default Media;
