import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

export const useCompareVehiclesList = () => {
	return useSelector((state) => state?.compareVehicles?.compareVehicles);
};

export const useCompareVehicles = (uuid) => {
	const compareVehicles = useCompareVehiclesList();
	const containsVehicle = useCallback(
		() => Boolean(compareVehicles.length && compareVehicles.includes(uuid)),
		[compareVehicles, uuid]
	);
	const [selected, setSelected] = useState(containsVehicle);

	return {
		selected,
		setSelected,
		containsVehicle,
		compareVehicles
	};
};
