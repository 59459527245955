const CONTAIN_DISTANCE_VALUE = /^.*\s\d{1,3}(?:,\d{3})*/;
const VALID_ODOMETER_VALUE = /^\d{1,3}(?:,\d{3})*/;

const concateAttrRules = {
	odometer: VALID_ODOMETER_VALUE,
	locationDistance: CONTAIN_DISTANCE_VALUE
};

export const checkConcaternateAttr = (attrName, value) => {
	const rule = concateAttrRules[attrName];
	if (!rule) return true;
	return rule.test(value);
};
