import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import { isBrowser } from 'ws-scripts/modules/environment';

/**
 * Waits for inventory data to be present and logs the timing to New Relic.
 * This function has some of the same code as `waitForInvData()` in `wsm-srp-utilities`.
 * @param widgetName
 */

export const waitForInventory = (widgetName) => {
	let waitForInvDataHandle;

	function updateWidget(arg) {
		if (Array.isArray(arg) && arg.length && widgetName) {
			const widgetTrim = widgetName.trim();
			const {
				timingData: { requestDuration, initialStartTime }
			} = arg[0];
			const dateNow = Date.now();
			setNewRelicCustomAttribute(
				`${widgetTrim}-request-duration`,
				requestDuration
			);
			setNewRelicCustomAttribute(
				`${widgetTrim}-render-duration`,
				dateNow - initialStartTime - requestDuration
			);
			setNewRelicCustomAttribute(
				`${widgetTrim}-total-duration`,
				dateNow - initialStartTime
			);
		}
	}

	function checkForInvData() {
		if (isBrowser && window.DDC?.InvData?.inventory?.inventory) {
			if (window.DDC?.pubsub) {
				window.DDC.pubsub.subscribe(
					'ws-inv-data/inventory',
					updateWidget
				);
			}
			clearInterval(waitForInvDataHandle);
			updateWidget();
		}
	}
	waitForInvDataHandle = setInterval(checkForInvData, 100);
};
