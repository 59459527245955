import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

const SpotIllustration = ({ spotName, spotWidth, spotHeight }) => {
	return (
		<SVG
			src={`/static/assets/illustrations/spot/${spotName}.svg`}
			aria-hidden="true"
			width={spotWidth}
			height={spotHeight}
			loader={
				<div
					style={{
						width: `${spotWidth}px`,
						height: `${spotHeight}px`
					}}
				/>
			}
		/>
	);
};

SpotIllustration.propTypes = {
	spotName: PropTypes.string.isRequired,
	spotWidth: PropTypes.string.isRequired,
	spotHeight: PropTypes.string.isRequired
};

export default SpotIllustration;
