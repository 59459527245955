import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const offSiteDataSlice = createSlice({
	name: 'offSiteData',
	initialState,
	reducers: {
		setOffSiteData(state, action) {
			return {
				...state,
				...action.payload
			};
		}
	}
});

export const { setOffSiteData } = offSiteDataSlice.actions;

export default offSiteDataSlice.reducer;
