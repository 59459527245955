import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const userProfileSlice = createSlice({
	name: 'userProfile',
	initialState,
	reducers: {
		setUserProfile(state, action) {
			const { userProfile } = action.payload;
			return {
				...state,
				...userProfile
			};
		}
	}
});

export const { setUserProfile } = userProfileSlice.actions;

export default userProfileSlice.reducer;
