import { trackEvent } from 'ddc-track-event';

/**
 * Uses the InvData.setFacetSelection method to define update to facets and fires
 * a tracking event.
 * @param {object} e An event
 * @param {string} id A string for facet id
 * @param {string} value A string for facet value
 * @param {string} widgetName A string for widgetName
 * @param {string} windowId A string for windowId
 */
export const removeFilter = ({ e, id, value, widgetName, windowId }) => {
	const appliedFilters = window.DDC.InvData.getAppliedFilters();
	if (appliedFilters) {
		const filter = appliedFilters.find((item) => item.id === id);
		const additionalFilters = filter
			? filter.values
					.filter(
						(item) =>
							item.value !== value || filter.id === 'geoRadius'
					)
					.map((item) =>
						filter.id === 'geoRadius' ? '0' : item.value
					)
			: [];

		trackEvent(widgetName, windowId, {
			action: 'removed',
			element: `${id} chip`,
			elementCTA: value,
			result: 'Results updated with facet removed'
		});

		window.DDC.InvData.setFilterSelections({
			filterName: id,
			filterValues: additionalFilters,
			filterValue: value,
			isSelected: false,
			isChip: true,
			element: e.target
		});
	}
};
