import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isModalOpen: false,
	videos: [],
	uuid: '',
	title: '',
	link: '',
	phone: ''
};

const modalSettingsSlice = createSlice({
	name: 'modalSettings',
	initialState,
	reducers: {
		setModalSettings(state, action) {
			return {
				...state,
				...action.payload
			};
		}
	}
});

export const { setModalSettings } = modalSettingsSlice.actions;

export default modalSettingsSlice.reducer;
