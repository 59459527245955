import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { usePrefs, useRequestData } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import VehicleAttributeItem from './VehicleAttributeItem';

const VehicleAttributeList = ({ attributes = [], accountId }) => {
	const { useSimplifiedListing, layout } = useSelector(
		(state) => state.layout
	);
	const {
		commentsEnableOnMobile,
		simplifiedLimitedAttributes,
		showInformationTabs
	} = usePrefs();
	const { deviceType } = useRequestData();

	const displayDetailList = () => {
		return attributes.map((detail, idx) => {
			const {
				name: detailName,
				labeledValue: detailLabeledValue,
				normalizedValue = null
			} = detail;

			const swatchColor =
				normalizedValue && normalizedValue !== 'Other'
					? normalizedValue.toLowerCase()
					: null;
			if (
				detailName === 'comments' &&
				commentsEnableOnMobile !== 'true' &&
				deviceType === 'MOBILE'
			) {
				return null;
			}
			if (
				useSimplifiedListing &&
				simplifiedLimitedAttributes &&
				idx >= +simplifiedLimitedAttributes
			) {
				return null;
			}
			if (useSimplifiedListing && detailName === 'odometer') {
				return null;
			}
			if (detail.name === 'locationDistance') {
				setNewRelicCustomAttribute('location-distance-set', true);
			}
			return (
				<VehicleAttributeItem
					key={detailName}
					detailName={detailName}
					detailValue={detailLabeledValue}
					swatchColor={swatchColor}
					accountId={accountId}
				/>
			);
		});
	};
	return (
		<ul
			className={setClassNames([
				'vehicle-card-description',
				'list-unstyled',
				'w-100',
				deviceType === 'MOBILE' && useSimplifiedListing
					? 'ddc-font-size-small'
					: null,
				deviceType !== 'MOBILE' && useSimplifiedListing
					? 'ddc-font-size-xsmall'
					: null,
				layout === 'list' ||
				(useSimplifiedListing &&
					+simplifiedLimitedAttributes &&
					showInformationTabs?.toLowerCase().includes('info'))
					? 'single-col'
					: null
			])}
		>
			{displayDetailList()}
		</ul>
	);
};

VehicleAttributeList.propTypes = {
	attributes: PropTypes.arrayOf(PropTypes.shape({})),
	accountId: PropTypes.string
};

VehicleAttributeList.defaultProps = {
	attributes: [],
	accountId: ''
};

export default VehicleAttributeList;
