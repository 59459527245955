import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLabels } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { isBrowser } from 'ws-scripts/modules/environment';

const ShowOffSiteInfo = ({ handleClick }) => {
	const { offSiteData } = useSelector((state) => state);
	const labels = useLabels();
	const { offSiteItemCount = '' } = offSiteData;

	const handleShowOffSite = () => {
		handleClick();
		sessionStorage.setItem('showOffSite', 'true');
		if (isBrowser && window.DDC && window.DDC.pubsub) {
			window.DDC.pubsub.publish('ws-inv-listing/showOffSiteInventory');
		}
	};

	return (
		<div
			className={setClassNames([
				'offsite-info',
				'w-100',
				'position-absolute',
				'text-center',
				'center-block-transform'
			])}
		>
			<i className="ddc-icon ddc-icon-map ddc-icon-size-xxlarge" />
			<p className="h1 d-block">{labels.get('THATS_NOT_ALL')}</p>
			<p className="h3 mt-3 mb-4 d-block text-muted">
				{labels.get('WE_FOUND_SIMILAR')}
			</p>
			<button
				className="offsite-button btn-xs btn-default"
				onClick={handleShowOffSite}
				type="button"
			>
				<i className="ddc-icon ddc-icon-chevron-down pr-3" />
				{labels
					.get('VIEW_X_MATCHES_AT_OTHER_LOCATIONS')
					.replace('{count}', offSiteItemCount)}
			</button>
		</div>
	);
};

ShowOffSiteInfo.propTypes = {
	handleClick: PropTypes.func.isRequired
};

export default ShowOffSiteInfo;
