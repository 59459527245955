import { isBrowser } from 'ws-scripts/modules/environment';

/**
 * Returns a vehicle object from datalayer if it has the matching vehicle property being passed in.
 *
 * @param {string} name Property name containing value to be compared.
 * @param {string} value Value to compare against.
 * @returns {object}
 */
export const findVehicleByProperty = (name, value) =>
	isBrowser &&
	window.DDC &&
	window.DDC.dataLayer &&
	window.DDC.dataLayer.vehicles
		? window.DDC.dataLayer.vehicles.find(
				(vehicle) => vehicle[name] === value
		  ) || {}
		: {};

/**
 * Constructs a object of data for a single vehicle.
 * @param uuid
 * @returns {{trim: *, year: *, model: *, vin: *, attributes: *, make: *, pricing: *}}
 */
export const getVehicleObj = (uuid) => {
	const vehicle = findVehicleByProperty('uuid', uuid);
	return {
		bodyStyle: vehicle.bodyStyle,
		cityFuelEfficiency:
			vehicle.cityFuelEfficiency && vehicle.cityFuelEfficiency.toString(),
		condition: vehicle.inventoryType,
		engine: vehicle.engine,
		exteriorColor: vehicle.exteriorColor,
		highwayFuelEfficiency:
			vehicle.highwayFuelEfficiency &&
			vehicle.highwayFuelEfficiency.toString(),
		interiorColor: vehicle.interiorColor,
		internetPrice: vehicle.internetPrice,
		make: vehicle.make,
		model: vehicle.model,
		msrp: vehicle.msrp,
		odometer: vehicle.odometer && vehicle.odometer.toString(),
		options: vehicle.optionCodes,
		packages: vehicle.packages,
		position: vehicle.indexPosition,
		stockNumber: vehicle.stockNumber,
		transmission: vehicle.transmission,
		trim: vehicle.trim,
		vin: vehicle.vin,
		year: vehicle.modelYear && vehicle.modelYear.toString()
	};
};

/**
 * Publishes event for vehicle click tracking purposes.
 * @param uuid
 */
export const publishVehicleData = (uuid) => {
	const vehicleObj = getVehicleObj(uuid);
	if (
		Object.keys(vehicleObj).length > 0 &&
		isBrowser &&
		window.DDC &&
		window.DDC.pubsub
	) {
		window.DDC.pubsub.publish('ws-inv-listing/vehicle-tracking', {
			vehicleObj
		});
	}
};

/**
 * Based on the vehicle's properties, returns a "priority type" to be used for tracking.
 *
 * @param {boolean} isNewCarBoost The `isNewCarBoost` property on the vehicle object in inventory.
 * @param {boolean} spotlightedVehicle The `spotlightedVehicle` property on the vehicle object in inventory.
 * @returns {string}
 */
export const getListingPriorityType = (isNewCarBoost, spotlightedVehicle) => {
	// Prioritize 'ncb' over 'spv' if both are true
	if (isNewCarBoost === true) {
		return 'ncb';
	} else if (spotlightedVehicle === true) {
		return 'spv';
	} else {
		return 'standard';
	}
};

/**
 * Conditionally appends a `priorityType` parameter to the vehicle link. This parameter is used for tracking on the VDP.
 *
 * @param {string} link The `link` property on the vehicle object in inventory.
 * @param {string} priorityType String representing the "priority type" of the vehicle for tracking.
 * @returns {string}
 */
export const addTrackingParam = (link, priorityType) => {
	const paramString =
		priorityType !== 'standard' ? `?priorityType=${priorityType}` : '';
	return `${link}${paramString}`;
};
