// import React, { useState, useEffect } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { setClassNames } from 'ddc-classnames-js';
import { useFlags, usePrefs } from 'wsm-common-data';
import MediaContainer from './mediaSection/MediaContainer';
import DetailsContainer from './detailsSection/DetailsContainer';
import FooterContainer from './footerSection/FooterContainer';
import ShowOffSiteInfo from './ShowOffSiteInfo';
import { selectIsGridLayout } from '../../utilities/layout';

const VehicleCard = ({
	accounts,
	shouldShowCompare,
	enableMediaCarousel,
	incentives,
	inventoryIndex,
	setShowOffSite,
	vehicle,
	loadingDelayed,
	forwardedRef,
	usePlaceholderImage,
	shouldFullyRenderCard,
	hasCarouselImages,
	featuredPromotion
}) => {
	const flags = useFlags();
	const canRender = shouldFullyRenderCard && !vehicle.isPlaceholder; // use skeleton components for placeholders
	const isGridLayout = useSelector((state) => selectIsGridLayout(state));
	const {
		uuid,
		link,
		featuredPromotion: featuredPromotionAttr = false
	} = vehicle;
	const willRenderBlankPlaceholderImage =
		inventoryIndex === 0 &&
		vehicle.isPlaceholder === true &&
		flags['ws-inv-data-preload-inventory'];

	const { displaySaveIconOn } = usePrefs();

	let containerStyle = null;

	// this style is added to the parent of the save icon to show icon on to the vehicle image instead of in the vehicle card footer
	if (displaySaveIconOn === 'true') {
		containerStyle = {
			position: 'relative'
		};
	}

	return (
		<li
			ref={forwardedRef}
			key={uuid}
			className={setClassNames([
				'box',
				'box-border',
				'vehicle-card',
				'vehicle-card-detailed',
				isGridLayout ? null : 'vehicle-card-horizontal',
				setShowOffSite && 'vehicle-card-fade position-relative',
				(featuredPromotion || featuredPromotionAttr) &&
					'vehicle-card-featured',
				vehicle.offSite && 'vehicle-offsite'
			])}
			data-uuid={uuid}
			data-index={inventoryIndex}
			style={containerStyle}
		>
			{setShowOffSite && (
				<ShowOffSiteInfo handleClick={() => setShowOffSite(true)} />
			)}
			<div
				className={setClassNames([
					'vehicle-card-body',
					'ddc-font-size-small',
					isGridLayout ? null : 'd-sm-flex', // TODO: deprecate
					isGridLayout ? null : 'flex-sm-row' // TODO: deprecate
				])}
			>
				<MediaContainer
					{...{
						enableMediaCarousel,
						vehicle,
						loadingDelayed,
						usePlaceholderImage,
						inventoryIndex,
						hasCarouselImages
					}}
					// first placeholder - render blank image for lcp hack via ws-inv-data preload code
					shouldFullyRenderCard={
						canRender || willRenderBlankPlaceholderImage
					}
					accountPhone={accounts?.[vehicle?.accountId]?.phone}
					willRenderBlankPlaceholderImage={
						willRenderBlankPlaceholderImage
					}
				/>
				<DetailsContainer
					{...{
						vehicle,
						incentives,
						accounts
					}}
					shouldFullyRenderCard={canRender}
				/>
			</div>
			<FooterContainer
				{...{
					shouldShowCompare,
					vehicle,
					uuid,
					link
				}}
				shouldFullyRenderCard={canRender}
			/>
		</li>
	);
};

const WrappedVehicleCard = React.forwardRef((props, ref) => {
	return <VehicleCard {...props} forwardedRef={ref} />;
});

VehicleCard.propTypes = {
	enableMediaCarousel: PropTypes.bool.isRequired,
	hasCarouselImages: PropTypes.bool.isRequired,
	inventoryIndex: PropTypes.number.isRequired,
	incentives: PropTypes.shape({}).isRequired,
	accounts: PropTypes.shape({}).isRequired,
	vehicle: PropTypes.shape({
		images: PropTypes.arrayOf(
			PropTypes.shape({
				uri: PropTypes.string
			})
		),
		uuid: PropTypes.string,
		link: PropTypes.string,
		accountId: PropTypes.string,
		offSite: PropTypes.bool,
		isPlaceholder: PropTypes.bool,
		featuredPromotion: PropTypes.bool
	}).isRequired,
	setShowOffSite: PropTypes.func,
	shouldShowCompare: PropTypes.bool.isRequired,
	loadingDelayed: PropTypes.bool.isRequired,
	forwardedRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	]),
	usePlaceholderImage: PropTypes.bool,
	shouldFullyRenderCard: PropTypes.bool,
	featuredPromotion: PropTypes.bool
};

export default WrappedVehicleCard;
