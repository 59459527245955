import React from 'react';
import PropTypes from 'prop-types';

const ClearFiltersButton = ({ children, handleClick }) => (
	<button className="text-link" onClick={handleClick} type="button">
		{children}
	</button>
);

ClearFiltersButton.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
		.isRequired,
	handleClick: PropTypes.func.isRequired
};

export default ClearFiltersButton;
