export const calculateVideoSize = (
	viewportWidth,
	viewportHeight,
	deviceType
) => {
	let videoWidth;
	if (deviceType === 'MOBILE' || /mobile/gi.test(deviceType)) {
		if (viewportWidth > 630) {
			videoWidth = viewportWidth * 0.75;
		} else {
			videoWidth = viewportWidth <= 0 ? 630 : viewportWidth;
		}
	} else {
		const minDim = Math.min(viewportWidth, viewportHeight);
		videoWidth =
			minDim === viewportWidth
				? viewportWidth - 32
				: viewportHeight - 160;
	}
	return videoWidth;
};
