import accountDataReducer from './accountData';
import compareReducer from './compare';
import contentCardsReducer from './contentCards';
import franchiseListReducer from './franchiseList';
import layoutReducer from './layout';
import modalSettingsReducer from './modalSettings';
import offSiteDataReducer from './offSiteData';
import userProfileReducer from './userProfile';
import widgetInfoReducer from './widgetInfo';

export default {
	accountData: accountDataReducer,
	compareVehicles: compareReducer,
	contentCards: contentCardsReducer,
	franchiseList: franchiseListReducer,
	layout: layoutReducer,
	modalSettings: modalSettingsReducer,
	offSiteData: offSiteDataReducer,
	userProfile: userProfileReducer,
	widgetInfo: widgetInfoReducer
};
