import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useRequestData, usePrefs } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { trackEvent } from 'ddc-track-event';
import { PriceTitle, SimplePrice } from 'wsm-pricing-display';
import { Collapse } from 'react-bootstrap';
import {
	selectIsGridLayout,
	selectIsTabbedLayout
} from '../../../utilities/layout';
import { pricingMap } from '../../../utilities/pricingMap';
import { publishVehicleData } from '../../../utilities/vehicleTracking';
import ConditionalWrapper from '../../ConditionalWrapper';
import WebIntegrationPlaceholder from '../../WebIntegrationPlaceholder';

/**
 *
 * @param {Object} props
 * @returns
 */
const VehicleTitle = ({
	title,
	link,
	vehicleId,
	condition,
	pricing,
	accountInfo,
	isDisplayingEPrice,
	showExpandedPricing,
	setShowExpandedPricing,
	showNewCarBoostPaymentsInTitle
}) => {
	const { windowId, widgetName, deviceType } = useRequestData();
	const { showPricingSummary, showInformationTabs } = usePrefs();
	const { useSimplifiedListing } = useSelector((state) => state.layout);
	const isTabbedLayout = useSelector((state) => selectIsTabbedLayout(state));
	const isGridLayout = useSelector((state) => selectIsGridLayout(state));
	// TODO: remove mapping here (and in ws-detailed-pricing) and update wsm-pricing-display to use lowercase version once GVM databus is retired
	const componentPricing = pricingMap(pricing);
	const selectFinalPrice = (pItemArray) =>
		pItemArray?.find((item) => item.isFinalPrice);
	const priceItem = isDisplayingEPrice
		? selectFinalPrice(componentPricing.ePrice)
		: selectFinalPrice(componentPricing.dPrice);
	const formatTitle = ([subtitle, maintitle]) => {
		const formattedTitle = (
			<React.Fragment>
				<span
					className="ddc-font-size-small"
					dangerouslySetInnerHTML={{
						__html: `${!useSimplifiedListing ? subtitle : ''}`
					}}
				/>
				<span
					dangerouslySetInnerHTML={{
						__html: `${
							useSimplifiedListing ? subtitle : ''
						} ${maintitle}`
					}}
				/>
			</React.Fragment>
		);
		return typeof maintitle === 'undefined' ? subtitle : formattedTitle;
	};

	const showSimplePrice = !showInformationTabs
		?.toLowerCase()
		.includes('pricing');

	// NOTE: SimplePriceElement is added here for backward compatibility with SRP Pricing Style Editor css selectors,
	const simplePriceElement = (
		<ConditionalWrapper
			condition={useSimplifiedListing || false}
			wrapper={(children) => (
				<Collapse in={!showExpandedPricing}>
					<div>{children}</div>
				</Collapse>
			)}
		>
			<>
				<SimplePrice
					condition={condition}
					pricing={componentPricing}
					isGridLayout={isGridLayout}
					account={accountInfo}
					isDisplayingEPrice={isDisplayingEPrice}
					showExpandedPricing={showExpandedPricing}
					setShowExpandedPricing={setShowExpandedPricing}
					isNewCarBoost={showNewCarBoostPaymentsInTitle}
				/>
				{!showNewCarBoostPaymentsInTitle && (
					<WebIntegrationPlaceholder
						location="vehicle-payments-primary"
						classNames={['float-left', 'ddc-font-size-xsmall']}
					/>
				)}
			</>
		</ConditionalWrapper>
	);

	const conditionClassName = condition
		? `inv-type-${condition}`.toLowerCase().replace(/ /g, '-')
		: '';

	return (
		<h2
			data-style-editor-price={
				!!(isTabbedLayout && showPricingSummary === 'true')
			}
			className={setClassNames([
				'vehicle-card-title',
				'mt-0',
				useSimplifiedListing ? 'd-block mb-0' : 'd-flex',
				'justify-content-between',
				'align-items-end',
				useSimplifiedListing && deviceType === 'MOBILE' ? 'h4' : null,
				useSimplifiedListing && deviceType !== 'MOBILE' ? 'h5' : null,
				!useSimplifiedListing &&
				isTabbedLayout &&
				deviceType !== 'MOBILE'
					? 'h3'
					: null,
				conditionClassName
			])}
		>
			<a
				href={link}
				onClick={() => {
					trackEvent(widgetName, windowId, {
						element: 'Vehicle title',
						result: 'Navigated to VDP'
					});
					publishVehicleData(vehicleId);
				}}
			>
				{formatTitle(title)}
			</a>
			{showNewCarBoostPaymentsInTitle && (
				<WebIntegrationPlaceholder
					location="vehicle-payments-primary"
					classNames={['ddc-font-size-xsmall', 'mt-3']}
					isNewCarBoost={showNewCarBoostPaymentsInTitle}
				/>
			)}
			{isTabbedLayout &&
				!useSimplifiedListing &&
				priceItem &&
				showPricingSummary === 'true' && (
					<span
						className="text-right portal-price"
						id={`${vehicleId}_priceTitle`}
						data-style-editor-id={`srp-${conditionClassName}-portal-price-value`}
						data-style-editor-text={`.srp .vehicle-card-title.${conditionClassName} .portal-price`}
					>
						<PriceTitle
							pricing={priceItem}
							account={accountInfo}
							isMobile={deviceType === 'MOBILE'}
							isGridLayout={isGridLayout}
						/>
					</span>
				)}
			{useSimplifiedListing && showSimplePrice && simplePriceElement}
		</h2>
	);
};

VehicleTitle.propTypes = {
	pricing: PropTypes.shape({
		ePrice: PropTypes.arrayOf(PropTypes.shape({})),
		dPrice: PropTypes.arrayOf(PropTypes.shape({}))
	}).isRequired,
	accountInfo: PropTypes.shape({}).isRequired,
	title: PropTypes.arrayOf(PropTypes.string).isRequired,
	link: PropTypes.string.isRequired,
	vehicleId: PropTypes.string.isRequired,
	condition: PropTypes.string.isRequired,
	isDisplayingEPrice: PropTypes.bool.isRequired,
	showExpandedPricing: PropTypes.bool.isRequired,
	setShowExpandedPricing: PropTypes.func.isRequired,
	showNewCarBoostPaymentsInTitle: PropTypes.bool
};

export default VehicleTitle;
