import React from 'react';
import { setClassNames } from 'ddc-classnames-js';
import PropTypes from 'prop-types';

const IFrameVideoComponent = ({ video, accountId }) => {
	if (video) {
		const { uri, provider } = video;

		const wrapperClassNames = [
			'h-100',
			'd-flex',
			'justify-content-center',
			'align-items-center',
			'video-container'
		];

		const playerClassNames = [
			'd-flex',
			'justify-content-center',
			'align-items-center',
			'media-modal-dcpais'
		];

		return (
			<div className={setClassNames(wrapperClassNames)}>
				<iframe
					src={uri}
					title="dcpaisIframe"
					className={setClassNames(playerClassNames)}
					data-accountid={accountId}
					data-provider={provider}
				/>
			</div>
		);
	}

	// No video - nothing to see here
	return null;
};

IFrameVideoComponent.propTypes = {
	accountId: PropTypes.string.isRequired,
	video: PropTypes.shape({
		uri: PropTypes.string,
		provider: PropTypes.string
	}).isRequired
};

export default IFrameVideoComponent;
