import React from 'react';
import { useSelector } from 'react-redux';
import { useSitemap, useLabels } from 'wsm-common-data';

const checkValidSiteMap = (pageSiteMap = {}, srpAliases) => {
	let haveAllSiteMap = true;
	srpAliases.forEach((_, key) => {
		if (!pageSiteMap[key] || pageSiteMap[key] === key) {
			haveAllSiteMap = false;
		}
	});
	return haveAllSiteMap;
};

const getInvLinksConfig = (currentPageAlias, srpAliases) => {
	const otherLinkAlias = [];
	srpAliases.forEach((value, key) => {
		if (key !== currentPageAlias) {
			otherLinkAlias.push({ alias: key, type: value });
		}
	});
	return otherLinkAlias;
};

const InventoryLinks = () => {
	const labels = useLabels();
	const { pageAlias } = useSelector((state) => state.widgetInfo);
	const sitemap = useSitemap();
	const pageSiteMap = sitemap.getAll();
	const srpAliases = new Map([
		['INVENTORY_LISTING_DEFAULT_AUTO_USED', 'SHOP_USED_VEHICLES'],
		['INVENTORY_LISTING_DEFAULT_AUTO_NEW', 'SHOP_NEW_VEHICLES'],
		[
			'INVENTORY_LISTING_DEFAULT_AUTO_CERTIFIED_USED',
			'SHOP_CERTIFIED_VEHICLES'
		],
		['INVENTORY_LISTING_DEFAULT_AUTO_ALL', 'SHOP_ALL_VEHICLES']
	]);
	// Not render inv links on all inventory page alias or non default page aliases
	if (
		!checkValidSiteMap(pageSiteMap, srpAliases) ||
		pageAlias === 'INVENTORY_LISTING_DEFAULT_AUTO_ALL' ||
		!pageAlias.includes('LISTING_DEFAULT')
	) {
		return null;
	}

	const otherLinkAlias = getInvLinksConfig(pageAlias, srpAliases);

	const linksToOtherPages = otherLinkAlias.map((item) => {
		const { alias, type: srpType } = item;
		if (!srpType) {
			return null;
		}
		return (
			<div className="inv-link" key={`${srpType}-inv-link`}>
				<a
					className="btn-primary btn-block btn-sm btn"
					href={pageSiteMap[alias]}
					target="_self"
					alt={labels.get(srpType)}
				>
					{labels.get(srpType)}
				</a>
			</div>
		);
	});

	return (
		<div className="srp-no-results-links d-flex flex-wrap justify-content-center">
			{linksToOtherPages}
		</div>
	);
};

export default InventoryLinks;
