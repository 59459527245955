import { highlightedAttrList } from 'wsm-highlighted-attribute';

const prefControlledAttributesMapping = {
	[highlightedAttrList.LOCATION]: 'accountId'
};

export const getAttributeValue = (
	highlightedAttributes,
	attrName,
	useLabeledValue
) => {
	const attribute = highlightedAttributes?.find(
		(attr) => attr.name === attrName
	);
	return useLabeledValue ? attribute?.labeledValue : attribute?.value;
};

export const enhanceHighlightedAttributes = (
	vehicle,
	accounts,
	useSimplifiedListing
) => {
	const { accountId, status } = vehicle;
	let { highlightedAttributes = [] } = vehicle;
	if (!useSimplifiedListing) {
		// TODO: this additional code was added to support for config highlighted attributes by prefs for location and fuelType
		// TODO should be remove it when we completely migrate to controll config via IDC
		const prefControlledAttrs = Object.values(highlightedAttrList)
			.filter((attrName) =>
				Object.keys(prefControlledAttributesMapping).includes(attrName)
			)
			.map((attrName) => {
				const mappingKey = prefControlledAttributesMapping[attrName];
				return { name: attrName, value: vehicle[mappingKey] };
			});
		// Make preference controlled attributes not depend on IDC when toggle off use IDC flag
		const mergedHighlightedAttrs = [
			...highlightedAttributes,
			...prefControlledAttrs
		].reduce((current, attr) => {
			return { ...current, [attr.name]: attr };
		}, {});
		highlightedAttributes = Object.values(mergedHighlightedAttrs);
	}

	const account = accounts[accountId];
	return highlightedAttributes.map((attribute) => {
		if (attribute.name === highlightedAttrList.ESTIMATED_DELIVERY_DATE) {
			return {
				...attribute,
				value: { status, estimatedDeliveryDate: attribute.value }
			};
		}
		if (attribute.name === highlightedAttrList.LOCATION) {
			return { ...attribute, value: account };
		}
		return attribute;
	});
};
