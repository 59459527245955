import { hasUrlParam, setUrlParam } from 'wsm-srp-utilities';

/**
 * Determines if a specific URL matches conditional checks.
 * Returns a boolean that checks for specific Dealer.com image URLs.
 * @param {string} url - A URL string value
 */

export const isDealerImage = (url) => {
	const domainRegex =
		/(.*\/\/.*)?(pictures|images|static)(\.)([^.]+\.)?dealer\.com(.*)/;
	return domainRegex.test(url);
};

/**
 * Determines if Akamai Image Manager resize parameters should be added to an input Image URL.
 * Returns a string of the input URL with image resize parameters added.
 * @param {string} url - A URL string value
 */
export const addImageParams = (url) => {
	const IMAGE_WIDTH = 520;

	const imageUrl = isDealerImage(url)
		? setUrlParam(url, 'impolicy=downsize_bkpt&imdensity=1')
		: url;

	let src = imageUrl;

	const isOptimized =
		hasUrlParam(imageUrl, 'impolicy') && !hasUrlParam(url, 'impolicy');

	if (isOptimized) {
		src = setUrlParam(imageUrl, `w=${IMAGE_WIDTH}`);
	}

	// Temporary hack to fix image loading on non-prod.
	src = src?.replace('pictures.qa.dealer.com', 'pictures.dealer.com');

	return src;
};
