import PropTypes from 'prop-types';
import React from 'react';
import { usePrefs, useLabels, useRequestData } from 'wsm-common-data';
import {
	estimatedDeliveryDate,
	estimatedDeliveryFee
} from 'wsm-delivery-estimation';

const Delivery = ({ distance }) => {
	const labels = useLabels();
	const {
		deliveryMax,
		deliveryBaseCost,
		baseDeliveryRadius,
		deliveryCostPerMile,
		deliveryDateMilesPerDay,
		deliveryDateMinimumDays
	} = usePrefs();
	const { locale } = useRequestData();

	if (distance === null || distance === undefined) {
		return null;
	}

	const deliveryMaxValue = parseInt(deliveryMax, 10);
	if (!Number.isNaN(deliveryMaxValue) && distance > deliveryMaxValue) {
		return (
			<div className="text-muted" data-testid="delivery-estimation">
				{labels.get('DELIVERY_SERVICE_UNAVAILABLE')}
			</div>
		);
	}

	const getLabeledValue = (label, value) => {
		return labels.get(label).replace('{fee}', value).trim();
	};

	const deliveryFee = estimatedDeliveryFee(
		distance,
		deliveryBaseCost,
		baseDeliveryRadius,
		deliveryCostPerMile
	);

	const deliveryDate = estimatedDeliveryDate(
		distance,
		locale,
		deliveryDateMilesPerDay,
		deliveryDateMinimumDays
	);

	if (deliveryFee === null || !deliveryDate) {
		return null;
	}

	const estimatedFeeSection =
		deliveryFee === 0 ? (
			<strong>{labels.get('DELIVERY_FREE')},</strong>
		) : (
			`${getLabeledValue('DELIVERY_FEE_SRP', deliveryFee)},`
		);

	return (
		<div className="text-muted" data-testid="delivery-estimation">
			{estimatedFeeSection} {labels.get('GET_IT').toLowerCase()}{' '}
			{deliveryDate}
		</div>
	);
};

Delivery.propTypes = {
	distance: PropTypes.number.isRequired
};

export default Delivery;
