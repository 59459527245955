import PropTypes from 'prop-types';
import React from 'react';
import { setClassNames } from 'ddc-classnames-js';

class DDCPlayerVideoComponent extends React.Component {
	componentDidMount() {
		if (typeof window.DDC !== 'undefined') {
			if (
				this.props.video &&
				window.DDC &&
				window.DDC.modules &&
				window.DDC.modules.video &&
				typeof $ !== 'undefined'
			) {
				$(document).trigger('modulesRefresh', window.DDC.modules.video);
			}
		}
	}

	render() {
		if (this.props.video) {
			const {
				videoWidth,
				video: { uri },
				uuid,
				accountId
			} = this.props;

			const baseClassNames = [
				'h-100',
				'd-flex',
				'justify-content-center',
				'align-items-center'
			];
			const wrapperClassNames = new Set([
				...baseClassNames,
				'video-container'
			]);
			const playerClassNames = [...baseClassNames, 'ddcPlayer-holder'];

			return (
				<div className={setClassNames(wrapperClassNames)}>
					<div
						style={{
							maxWidth: videoWidth,
							maxHeight: Math.round(videoWidth * 0.7)
						}}
						id={`video-${uuid}`}
						className={setClassNames(playerClassNames)}
						data-ddcplayerinfo={`${encodeURIComponent(
							uri
						)}-${videoWidth}-${Math.round(videoWidth * 0.7)}`}
						data-accountid={accountId}
						data-useprepostroll="false"
						data-autoplay="false"
					/>
				</div>
			);
		}

		// No video - nothing to see here
		return null;
	}
}

DDCPlayerVideoComponent.propTypes = {
	accountId: PropTypes.string,
	videoWidth: PropTypes.number.isRequired,
	video: PropTypes.shape({
		id: PropTypes.number,
		uri: PropTypes.string
	}),
	uuid: PropTypes.string
};

DDCPlayerVideoComponent.defaultProps = {
	video: undefined,
	uuid: '',
	accountId: ''
};

export default DDCPlayerVideoComponent;
