import { useSelector } from 'react-redux';
import { useFlags, useSitemap } from 'wsm-common-data';
import { getCompareLink } from '../utilities/compare';

export const useCompareLink = () => {
	const { compareVehicles } = useSelector((state) => state.compareVehicles);
	const { pageAlias } = useSelector((state) => state.widgetInfo);

	const flags = useFlags();
	const sitemap = useSitemap();

	const compareLink = getCompareLink({
		flags,
		sitemap,
		compareVehicles,
		pageAlias
	});

	return compareLink;
};
