import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { setClassNames } from 'ddc-classnames-js';
import './Skeleton.scss';
import MediaContainer from '../mediaSection/MediaContainer';
import FooterContainer from '../footerSection/FooterContainer';
import DetailsContainer from '../detailsSection/DetailsContainer';
import { selectIsGridLayout } from '../../../utilities/layout';

const VehicleCardSkeleton = ({ index, uuid, forwardedRef }) => {
	const isGridLayout = useSelector((state) => selectIsGridLayout(state));
	return (
		<li
			className={setClassNames([
				'box',
				'box-border',
				'vehicle-card',
				'vehicle-card-detailed',
				isGridLayout ? null : 'vehicle-card-horizontal horizontal'
			])}
			data-uuid={uuid}
			data-index={index}
			ref={forwardedRef}
		>
			<div
				className={setClassNames([
					'vehicle-card-body',
					'ddc-font-size-small',
					isGridLayout ? null : 'd-sm-flex flex-sm-row' // TODO: deprecate
				])}
			>
				<MediaContainer shouldFullyRenderCard={false} />
				<DetailsContainer shouldFullyRenderCard={false} />
			</div>
			<FooterContainer vehicle={{ uuid }} shouldFullyRenderCard={false} />
		</li>
	);
};

const WrappedVehicleCardSkeleton = React.forwardRef((props, ref) => {
	return <VehicleCardSkeleton {...props} forwardedRef={ref} />;
});

VehicleCardSkeleton.propTypes = {
	index: PropTypes.number,
	// only needed for substitution, not needed during initial load
	forwardedRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	]),
	uuid: PropTypes.string
};

VehicleCardSkeleton.defaultProps = {
	forwardedRef: null,
	uuid: undefined,
	index: undefined
};

export default WrappedVehicleCardSkeleton;
