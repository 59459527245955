import React from 'react';
import PropTypes from 'prop-types';
import FilterItem from './FilterItem';

const FilterItemList = ({ appliedFilters }) => {
	if (appliedFilters.length <= 0) {
		return null;
	}

	return (
		<ul className="srp-low-result-filters-list mb-6 px-0 d-flex justify-content-center flex-wrap">
			{appliedFilters.map((filter) => {
				// Not render locations filter in no results chip area
				if (filter?.id === 'geoRadius') {
					return null;
				}
				return filter.values.map((item) => {
					return (
						<FilterItem
							{...item}
							key={[filter.id, item.value].join('-')}
							filterId={filter.id}
							filterLabel={filter.label}
							showRemoveButton={item.showRemoveButton}
						/>
					);
				});
			})}
		</ul>
	);
};

FilterItemList.propTypes = {
	appliedFilters: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			values: PropTypes.arrayOf(PropTypes.shape({}))
		})
	)
};

export default React.memo(FilterItemList);
