import React from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from 'ddc-track-event';
import { useRequestData } from 'wsm-common-data';
import { publishVehicleData } from '../../../utilities/vehicleTracking';

const VehicleLink = ({ link, uuid, children }) => {
	const { windowId, widgetName } = useRequestData();
	return link ? (
		<a
			href={link}
			onClick={() => {
				trackEvent(widgetName, windowId, {
					element: 'Vehicle photo',
					result: 'Navigated to VDP',
					uuid
				});
				publishVehicleData(uuid);
			}}
		>
			{children}
		</a>
	) : (
		children
	);
};

export default VehicleLink;

VehicleLink.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
		.isRequired,
	link: PropTypes.string,
	uuid: PropTypes.string.isRequired
};

VehicleLink.defaultProps = {
	link: ''
};
