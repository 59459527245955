import React from 'react';
import styled from 'styled-components';

const Spinner = () => (
	<StyledSpinner viewBox="0 0 50 50" style={{ display: 'inline-block' }}>
		<div className="loader" />
	</StyledSpinner>
);

const StyledSpinner = styled.div`
	border: 2px solid #d9d9d9;
	border-radius: 50%;
	border-top: 2px solid #999999;
	width: 12px;
	height: 12px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;

	/* Safari */
	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

export default Spinner;
