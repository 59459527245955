import React from 'react';
import PropTypes from 'prop-types';
import Media from './Media';
import MediaSkeleton, {
	MediaSkeletonContainerClasses
} from '../skeleton/MediaSkeleton';

const MediaContainer = ({ shouldFullyRenderCard, ...props }) => (
	<div
		className={`vehicle-card-media-container${
			!shouldFullyRenderCard
				? ` ${MediaSkeletonContainerClasses.join(' ')}`
				: ''
		}`}
	>
		{shouldFullyRenderCard ? <Media {...props} /> : <MediaSkeleton />}
	</div>
);

MediaContainer.propTypes = {
	shouldFullyRenderCard: PropTypes.bool.isRequired
};

export default MediaContainer;
