import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLabels, useRequestData } from 'wsm-common-data';
import { removeAllFilters, replaceContentWithJSX } from 'wsm-srp-utilities';
import { trackEvent } from 'ddc-track-event';
import { addNewRelicPageAction } from 'ddc-new-relic';
import ClearFiltersButton from './ClearFiltersButton';
import ErrorAlert from './ErrorAlert';

const NoResults = ({ appliedFilters }) => {
	const { widgetName, windowId } = useRequestData();
	const labels = useLabels();
	const [hasTrackedData, setHasTrackedData] = useState(false);
	const [noResultsMessage, setNoResultsMessage] = useState(null);
	const [currentFilters, setCurrentFilters] = useState(null);

	useEffect(() => {
		const clearButton = {
			'{{clearButton}}': (
				<ClearFiltersButton
					handleClick={() =>
						removeAllFilters(
							labels.get('CLEARING_YOUR_FILTERS'),
							widgetName,
							windowId
						)
					}
				>
					{labels.get('CLEARING_YOUR_FILTERS')}
				</ClearFiltersButton>
			)
		};
		if (
			(!hasTrackedData && appliedFilters) ||
			(appliedFilters &&
				!window._.isEqual(appliedFilters, currentFilters))
		) {
			const filterValues =
				appliedFilters.length === 1 ? appliedFilters[0].values : [];
			const filterLabel =
				filterValues.length === 1 ? filterValues[0].label : '';
			const filterId =
				appliedFilters.length === 1 ? appliedFilters[0].id : '';

			setNoResultsMessage(
				filterLabel
					? replaceContentWithJSX(
							filterId === 'geoRadius'
								? labels.get('CLEAR_MESSAGE_SINGLE_FACET_END')
								: labels.get('CLEAR_MESSAGE_SINGLE_FACET'),
							{
								'{{facetLabel}}': filterLabel,
								...clearButton
							}
					  )
					: replaceContentWithJSX(
							labels.get('CLEAR_MESSAGE_MULTI_FACET'),
							clearButton
					  )
			);

			trackEvent(widgetName, windowId, {
				element: 'No vehicle results',
				action: 'impression',
				result: 'No results message presented',
				nonInteractive: true
			});
			addNewRelicPageAction('WS INV LISTING NO RESULTS', {
				isNoResults: true,
				appliedFilters
			});
			setHasTrackedData(true);
			setCurrentFilters(appliedFilters);
		}
	}, [
		appliedFilters,
		currentFilters,
		hasTrackedData,
		labels,
		widgetName,
		windowId
	]);

	if (noResultsMessage) {
		return <ErrorAlert errorLabel={noResultsMessage} />;
	}
	return null;
};

NoResults.propTypes = {
	appliedFilters: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			values: PropTypes.arrayOf(PropTypes.shape({}))
		})
	)
};

NoResults.defaultProps = {
	appliedFilters: null
};

export default NoResults;
