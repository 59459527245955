import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { ErrorBoundary } from 'wsm-error-boundary';

const WebIntegrationPlaceholder = ({
	location,
	classNames = [],
	tagName = 'div',
	forwardedRef = null,
	isNewCarBoost = false
}) => {
	const HtmlTag = `${tagName}`;
	return (
		<ErrorBoundary newRelicPageAction="ws-inv-listing error boundary: WIAPI placeholder location error">
			<HtmlTag
				data-location={location}
				position={location}
				ref={forwardedRef}
				key={location}
				className={setClassNames([...classNames])}
				data-vehicle-new-car-boost={isNewCarBoost}
			/>
		</ErrorBoundary>
	);
};

const ReferencedWebIntegrationPlaceholder = React.forwardRef((props, ref) => {
	return <WebIntegrationPlaceholder {...props} forwardedRef={ref} />;
});

WebIntegrationPlaceholder.propTypes = {
	location: PropTypes.string.isRequired,
	classNames: PropTypes.arrayOf(PropTypes.string),
	tagName: PropTypes.string,
	forwardedRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	]),
	isNewCarBoost: PropTypes.bool
};

export default ReferencedWebIntegrationPlaceholder;
