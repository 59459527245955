import React from 'react';
import PropTypes from 'prop-types';
import MyCars from './MyCars';
import CompareCheckbox from './CompareCheckbox';
import DetailsButton from './DetailsButton';

const Footer = ({
	shouldShowCompare,
	vehicle,
	containsVehicle,
	selected,
	setSelected,
	compareVehicles
}) => {
	const { uuid, link } = vehicle;

	return (
		<>
			{shouldShowCompare && (
				<CompareCheckbox
					uuid={uuid}
					containsVehicle={containsVehicle}
					selected={selected}
					setSelected={setSelected}
					compareVehicles={compareVehicles}
				/>
			)}
			<MyCars vehicle={vehicle} />
			<DetailsButton uuid={uuid} link={link} title={vehicle.title} />
		</>
	);
};

Footer.propTypes = {
	shouldShowCompare: PropTypes.bool.isRequired,
	vehicle: PropTypes.shape({
		uuid: PropTypes.string,
		link: PropTypes.string,
		title: PropTypes.arrayOf(PropTypes.string)
	}).isRequired,
	containsVehicle: PropTypes.func.isRequired,
	selected: PropTypes.bool.isRequired,
	setSelected: PropTypes.func.isRequired,
	compareVehicles: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Footer;
