import { isBrowser } from 'ws-scripts/modules/environment';

/**
 * ✧･ﾟ: *✧･ﾟ:* ♥*♡∞:｡.｡ WAIT FOR SOMETHING ｡.｡:∞♡*♥ *:･ﾟ✧*:･ﾟ✧
 * Wait for something to appear in the window object, and then deal with it, whatever it is.
 * If you were hoping for idiomatic react, you've come to the wrong collection of osiris widgets
 * @param {string} where Path within the window object you're expecting and wish to wait for
 * @param {number} delay Interval to check for "where" in ms
 * @param {function} doBefore Function called before waiting
 * @param {function} doAfter Function called after successfully waiting
 * @param {number} giveUpAfter Time in ms to give up waiting
 * @param {function} onGiveUp Function called after giving up
 */
export const waitForSomething = ({
	where,
	delay = 100,
	doBefore = () => {},
	doAfter = () => {},
	giveUpAfter = 10000,
	onGiveUp = () => {}
}) => {
	if (isBrowser) {
		let wait;
		doBefore();
		const timeoutID = setTimeout(() => {
			onGiveUp();
			clearInterval(wait);
		}, giveUpAfter);
		const check = () => {
			const evaluatedPath = where
				.split('.')
				.reduce((o, i) => (o[i] ? o[i] : false), window);
			if (evaluatedPath) {
				clearTimeout(timeoutID);
				clearInterval(wait);
				doAfter();
			}
		};
		wait = setInterval(check, delay);
	}
};
