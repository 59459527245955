import { isBrowser } from 'ws-scripts/modules/environment';

const getCompareBaseUrl = ({ flags, sitemap }) => {
	const useNewComparePage = flags['srp-use-new-compare-page'];

	// Using new compare page
	if (useNewComparePage) {
		return sitemap.get('INVENTORY_VEHICLE_COMPARE_DEFAULT');
	}

	// Using old compare page
	if (isBrowser) {
		return window.DDC?.InvData?.COMPARE_PAGE;
	}

	// Old compare page fallback value for SSR
	return '';
};

export const getCompareLink = ({
	compareVehicles,
	flags,
	sitemap,
	pageAlias
}) => {
	const useNewComparePage = flags['srp-use-new-compare-page'];

	const compareBaseUrl = getCompareBaseUrl({ flags, sitemap });

	if (!compareBaseUrl) {
		return '';
	}

	let url = compareBaseUrl.concat(
		`?itemIds=${encodeURIComponent(compareVehicles.join())}`
	);

	if (useNewComparePage) {
		url = url.concat(`&searchResultsPage=${encodeURIComponent(pageAlias)}`);
	}

	return url;
};

/**
 * helper that lets you know whether or not to show the compare functionality
 * @param viewportWidth
 * @param enableCompare Preference
 * @param deviceType
 * @returns {boolean}
 */
export const getShouldShowCompare = (enableCompare) => {
	// Compare functionality is enabled now on both desktop and mobile
	return enableCompare === 'true';
};
