import React from 'react';
import Skeleton from 'wsm-skeleton';

export const FooterSkeletonContainerClasses = ['p-0', 'mt-8'];

const FooterSkeleton = () => (
	<Skeleton height="50px" dataTestId="footer-skeleton" />
);

export default FooterSkeleton;
