import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	layout: 'list',
	isSmallScreen: false,
	useSimplifiedListing: false
};

const layoutSlice = createSlice({
	name: 'layout',
	initialState,
	reducers: {
		setLayout(state, action) {
			return {
				...state,
				...action.payload
			};
		}
	}
});

export const { setLayout } = layoutSlice.actions;

export default layoutSlice.reducer;
