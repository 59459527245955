import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	pageAlias: '',
	siteId: '',
	accountId: '',
	featuredPromotion: ''
};

const widgetInfoSlice = createSlice({
	name: 'widgetInfo',
	initialState,
	reducers: {
		setWidgetInfo(state, action) {
			return {
				...state,
				...action.payload
			};
		}
	}
});

export const { setWidgetInfo } = widgetInfoSlice.actions;

export default widgetInfoSlice.reducer;
