/**
 * Sorts an array of objects with Quick Notes data based on the order of the HighlightedAttribute's displayed
 * @param {{badgeClasses: string[], children: string, popoverAria: string, popoverContent: string, type: string}}
 * highlightedAttrsArray - Array containing HighlightedAttributes components
 * @param {{type: string, highlightedAttr: React.JSX.Element, note: string}[]} quickNotesAttrsArray - Array containing objects with Quick Notes data
 * @returns {{type: string, highlightedAttr: React.JSX.Element, note: string}[]} The quickNotesAttrsArray sorted
 */
export const sortQuickNotes = (highlightedAttrsArray, quickNotesAttrsArray) => {
	const filteredHighlightedAttrs = highlightedAttrsArray.filter(
		(attr) => attr
	);
	const highlightedAttrTypeToIndex = new Map();

	filteredHighlightedAttrs.forEach((attribute, index) => {
		const {
			props: { type }
		} = attribute;
		highlightedAttrTypeToIndex.set(type, index);
	});

	return quickNotesAttrsArray.sort(
		(a, b) =>
			highlightedAttrTypeToIndex.get(a.type) -
			highlightedAttrTypeToIndex.get(b.type)
	);
};
