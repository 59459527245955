/* eslint-disable no-param-reassign */
// param reassignment allowed by RTK
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	compareVehicles: []
};

export const compareSlice = createSlice({
	name: 'compareVehicles',
	initialState,
	reducers: {
		addCompareVehicle(state, action) {
			state.compareVehicles = [...state.compareVehicles, action.payload];
		},
		removeCompareVehicle(state, action) {
			state.compareVehicles = state.compareVehicles.filter(
				(vehicleUUID) => vehicleUUID !== action.payload
			);
		},
		removeAllVehicles(state) {
			state.compareVehicles = [];
		}
	}
});

export const { addCompareVehicle, removeCompareVehicle, removeAllVehicles } =
	compareSlice.actions;

export default compareSlice.reducer;
