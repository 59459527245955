import React from 'react';
import PropTypes from 'prop-types';

const Collapse = (props) => {
	const { id, children, toggleCollapse } = props;

	if (toggleCollapse) {
		return (
			<div id={`${id}-collapse`} className="collapse collapse-content">
				{children}
			</div>
		);
	}

	return children;
};

Collapse.propTypes = {
	id: PropTypes.string.isRequired,
	children: PropTypes.element.isRequired,
	toggleCollapse: PropTypes.bool
};

Collapse.defaultProps = {
	toggleCollapse: false
};

export default Collapse;
