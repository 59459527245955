/**
 * Determines if the current vehicle is in the list of vehicles whose insert locations should be restricted.
 *
 * @param {Array} vehicleTitle - The vehicle title array from the vehicle data.
 * @param {string} restrictInsertLocationsForVehicles - The value of the restrictInsertLocationsForVehicles preference.
 */

const getTypeFromInteger = (value) => {
	return value === 1 ? 'used' : 'new';
};

export const getTargetLocations = (
	vehicle,
	restrictInsertLocationsForVehicles
) => {
	const targetLocations = new Set();

	if (!restrictInsertLocationsForVehicles) {
		return targetLocations;
	}

	// Expected preference value format:
	// classification=wholesale,optionCodes=P0007,optionCodes=P1234:vehicle-ctas,vehicle-badge;classification=fleet:vehicle-media,vehicle-pricing;
	restrictInsertLocationsForVehicles.split(';').forEach((configEntry) => {
		if (configEntry.includes(':')) {
			let matchedCount = 0;
			const [matcherList, targets] = configEntry.split(':');
			const fieldMatchers = matcherList.trim().split(',');

			fieldMatchers.forEach((matcher) => {
				const [fieldName, fieldValue] = matcher.split('=');

				const normalizedFieldName =
					fieldName === 'inventoryType' ? 'type' : fieldName;
				const normalizedFieldValue =
					normalizedFieldName === 'type'
						? getTypeFromInteger(vehicle[fieldName])
						: vehicle[fieldName];

				if (Array.isArray(normalizedFieldValue)) {
					if (normalizedFieldValue.indexOf(fieldValue) > -1) {
						matchedCount += 1;
					}
				} else if (
					normalizedFieldValue &&
					normalizedFieldValue
						.toLowerCase()
						.match(fieldValue.toLowerCase())
				) {
					matchedCount += 1;
				}
			});

			if (matchedCount === fieldMatchers.length) {
				targets
					.trim()
					.split(',')
					.forEach((location) => {
						targetLocations.add(location);
					});
			}
		}
	});

	return targetLocations;
};

export const getVehicleLocationName = (
	name,
	targetLocations,
	restrictInsertLocationsForVehicles
) => {
	if (targetLocations.has(name)) {
		return `${name}-custom`;
	}

	const isRestricted =
		restrictInsertLocationsForVehicles &&
		restrictInsertLocationsForVehicles.length > 0;

	return isRestricted ? `${name} ${name}-custom` : name;
};
