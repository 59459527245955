import { isBrowser } from 'ws-scripts/modules/environment';

/**
 * helper that lets you know if you're on a small screen (one that is under 768 in width)
 *
 * @param viewportWidth
 * @returns {boolean}
 */
export const getIsSmallScreen = (viewportWidth) => {
	return viewportWidth < 768;
};

// "Grid View" only kicks in above tablet size.
// A list-type horizontal card still shows for > 480 && < 768
export const selectIsGridLayout = (state) => {
	return state.layout.layout === 'grid' && !state.layout.isSmallScreen;
};

export const selectIsTabbedLayout = (state) => {
	return state.layout.isSmallScreen || selectIsGridLayout(state);
};

export const getSpotIconSize = (deviceType) => {
	const screen =
		deviceType === 'DESKTOP' && isBrowser && window.screen?.width > 1200
			? 'HIRES'
			: deviceType;
	return (
		{
			HIRES: '104',
			DESKTOP: '96',
			MOBILE: '80'
		}[screen] ?? '96'
	);
};
