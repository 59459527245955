import React from 'react';
import PropTypes from 'prop-types';

const Alert = ({ children, alertVariantClass, alertIconClass }) => {
	return (
		<div className={`alert ${alertVariantClass}`} data-testid="alert">
			<i className={`ddc-icon ${alertIconClass}`} aria-hidden="true" />
			<div className="alert-content">{children}</div>
		</div>
	);
};

Alert.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
		.isRequired,
	alertVariantClass: PropTypes.string.isRequired,
	alertIconClass: PropTypes.string.isRequired
};

export default Alert;
