export default function sitemap(state = {}, action = null) {
	if (!action.error && action.payload !== undefined) {
		switch (action.type) {
			case 'WS_SCRIPTS_ADD_SITEMAP':
				return {
					...state,
					...action.payload
				};

			// no default
		}
	}
	return state;
}
