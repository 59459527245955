import React, { useEffect, useState } from 'react';
import { isBrowser } from 'ws-scripts/modules/environment';
import PropTypes from 'prop-types';
import { useRequestData, useLabels } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { trackEvent } from 'ddc-track-event';

const validateZip = (val) => {
	const postalRegEx =
		/(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\d{1}[A-Za-z]{1} *\d{1}[A-Za-z]{1}\d{1}$)/g;
	const isPostalCode = new RegExp(postalRegEx);

	return isPostalCode.test(val);
};

const LocationInput = ({ geoZip }) => {
	const { widgetName, windowId } = useRequestData();
	const labels = useLabels();
	const [isValidZip, setValidZip] = useState(false);
	const [isAlert, setAlert] = useState(false);
	const [distance, setDistance] = useState(geoZip[0]);

	const fromLabel = labels.get('FROM_YOUR_LOCATION');

	useEffect(() => {
		if (geoZip[0] !== distance) {
			setDistance(geoZip[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [geoZip]);

	const updateLocation = (inputZipCode, target) => {
		if (inputZipCode && isBrowser) {
			setDistance([inputZipCode]);
			window.DDC.InvData.setFilterSelections({
				filterName: 'geoZip',
				filterValues: [inputZipCode],
				filterValue: [inputZipCode],
				element: target,
				isSelected: true,
				isSlider: false,
				isChip: false
			});
			window.localStorage.setItem('userLocation', inputZipCode);
		}
	};

	const handleInput = (e) => {
		const newInput = e.target.value;
		setValidZip(validateZip(newInput));
		if (newInput !== distance) {
			setDistance(newInput);
		}
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();

		if (e.key === 'Enter' || e.type === 'click') {
			if (isValidZip) {
				updateLocation(distance, e.target);
				setAlert(false);
				trackEvent(widgetName, windowId, {
					action: 'applied',
					element: 'Zip Code selection',
					elementCTA:
						e.key === 'Enter' ? 'Text input' : 'Text input button',
					result: 'Results updated with facet added'
				});
			} else {
				setAlert(true);
			}
		}
	};

	const form = (
		<form className="p-0">
			<div
				className={setClassNames([
					'd-flex',
					'w-100',
					'p-0',
					'form-control',
					'h-auto',
					isAlert ? 'invalid' : ''
				])}
			>
				<input
					className="form-control form-control-transparent"
					type="search"
					aria-label={labels.get('ENTER_A_NEW_ZIP_CODE')}
					onChange={handleInput}
					onKeyUp={handleFormSubmit}
					value={distance}
				/>
				<button
					className={setClassNames([
						'text-link',
						'px-4',
						'text-no-decoration',
						'mt-0'
					])}
					type="submit"
					onClick={handleFormSubmit}
					disabled={!isValidZip}
					title={labels.get('SEARCH')}
					aria-label={labels.get('SEARCH')}
				>
					<i
						id="skinny-arrow-right"
						className="ddc-icon ddc-icon-arrow1-right"
						aria-hidden="true"
					/>
				</button>
			</div>
		</form>
	);
	return (
		<span
			className={setClassNames([
				'd-flex flex-column',
				'distance-wrapper'
			])}
		>
			<span className="text-muted">{fromLabel}</span>
			{form}
			{isAlert && (
				<span className="d-block mt-1 text-danger">
					{labels.get('AUTOCOMPLETE_HELP_TEXT')}
				</span>
			)}
		</span>
	);
};

LocationInput.propTypes = {
	geoZip: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default LocationInput;
