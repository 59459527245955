import React from 'react';
import { isBrowser } from 'ws-scripts/modules/environment';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';

const GeoRange = ({ range, updateRange }) => {
	const { geoRadiusValues } =
		(isBrowser && window?.DDC?.InvData?.getGeoDataPrefs()) || {};
	const labels = useLabels();
	const geoLocationLabel = labels.get('DISTANCE');

	const getRadiusValues = () => {
		const optionsArr = [];
		if (!geoRadiusValues) {
			return optionsArr;
		}
		geoRadiusValues.split(',').forEach((value) => {
			const isAnyDistance = value === '0';
			optionsArr.push({
				value,
				label: isAnyDistance
					? `${labels.get('ANY_DISTANCE')}`
					: `${value} ${labels.get('MILES')}`
			});
		});

		return optionsArr;
	};

	return (
		<span className={setClassNames(['d-flex flex-column', 'geo-wrapper'])}>
			<span className="text-muted">{geoLocationLabel}</span>
			<div className="geo-select-box">
				<select
					className="form-control"
					aria-label={labels.get('ZIP_CODE_RANGE_DROPDOWN')}
					onChange={updateRange}
					value={range}
				>
					{getRadiusValues(geoRadiusValues).map(
						({ value, label }) => (
							<option key={value} value={value}>
								{label}
							</option>
						)
					)}
				</select>
				<i
					id="skinny-arrow-down"
					className="ddc-icon ddc-icon-arrow1-down"
					aria-hidden="true"
				/>
			</div>
		</span>
	);
};

GeoRange.propTypes = {
	range: PropTypes.string.isRequired,
	updateRange: PropTypes.func.isRequired
};

export default GeoRange;
