import React, { useState, useEffect } from 'react';
import { isBrowser } from 'ws-scripts/modules/environment';
import { usePrefs } from 'wsm-common-data';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import DetailsSkeleton, {
	DetailsSkeletonContainerClasses
} from '../skeleton/DetailsSkeleton';
import TitleSkeleton from '../skeleton/TitleSkeleton';
import VehicleTitle from './VehicleTitle';
import Details from './Details';
import {
	SHOW_PAYMENTS_NOTIFICATION,
	SHOW_PAYMENTS_LOCAL_STORAGE_KEY
} from '../../../global-constants';

const DetailsContainer = ({ shouldFullyRenderCard, ...props }) => {
	const [isDisplayingEPrice, setIsDisplayingEPrice] = useState(false);
	const [showExpandedPricing, setShowExpandedPricing] = useState(false); // todo pref sets initial state to show all pricing when configured
	const [isNewCarBoostStyle, setIsNewCarBoostStyle] = useState(false);
	const { useSimplifiedListing } = useSelector((state) => state.layout);
	const { showInformationTabs } = usePrefs();

	const isNewCarBoost = props.vehicle?.isNewCarBoost;

	useEffect(() => {
		// Retrieve the user showing payments from local storage
		// Setting new car boost style when page load
		const userShowPayments = JSON.parse(
			localStorage.getItem(SHOW_PAYMENTS_LOCAL_STORAGE_KEY)
		);
		setIsNewCarBoostStyle(userShowPayments?.show && isNewCarBoost);

		// Setting new car boost style based on the 'show-payments-notification' event
		let subscriber = null;
		const { pubsub } = (isBrowser && window.DDC) || {};
		if (pubsub) {
			const handleNotification = (notification) => {
				const { show } = notification;
				setIsNewCarBoostStyle(show && isNewCarBoost);
			};

			subscriber = pubsub.subscribe(
				SHOW_PAYMENTS_NOTIFICATION,
				handleNotification
			);
		}
		// Cleanup subscription on component unmount
		return () => {
			subscriber?.unsubscribe();
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	let newCarBoostPlacement;
	if (isNewCarBoostStyle) {
		newCarBoostPlacement =
			useSimplifiedListing &&
			!showInformationTabs?.toLowerCase().includes('pricing')
				? 'title'
				: 'details';
	}

	return (
		<div
			className={`vehicle-card-details-container${
				!shouldFullyRenderCard
					? ` ${DetailsSkeletonContainerClasses.join(' ')}`
					: ''
			}`}
		>
			{props.vehicle && !props.vehicle.isPlaceholder ? (
				<VehicleTitle
					title={props.vehicle.title}
					link={props.vehicle.link}
					vehicleId={props.vehicle.uuid}
					condition={props.vehicle.condition}
					pricing={props.vehicle.pricing || {}}
					isNewCarBoost={props.vehicle.isNewCarBoost}
					isDisplayingEPrice={isDisplayingEPrice}
					accountInfo={{ ...props.accounts[props.vehicle.accountId] }}
					showExpandedPricing={showExpandedPricing}
					setShowExpandedPricing={setShowExpandedPricing}
					showNewCarBoostPaymentsInTitle={
						newCarBoostPlacement === 'title'
					}
				/>
			) : (
				<TitleSkeleton />
			)}

			{shouldFullyRenderCard ? (
				<Details
					{...props}
					isDisplayingEPrice={isDisplayingEPrice}
					setIsDisplayingEPrice={setIsDisplayingEPrice}
					showExpandedPricing={showExpandedPricing}
					setShowExpandedPricing={setShowExpandedPricing}
					showNewCarBoostPaymentsInDetails={
						newCarBoostPlacement === 'details'
					}
				/>
			) : (
				<DetailsSkeleton />
			)}
		</div>
	);
};

DetailsContainer.propTypes = {
	vehicle: PropTypes.shape({
		title: PropTypes.arrayOf(PropTypes.string),
		uuid: PropTypes.string,
		link: PropTypes.string,
		condition: PropTypes.string,
		pricing: PropTypes.shape({}),
		accountId: PropTypes.string,
		isPlaceholder: PropTypes.bool,
		isNewCarBoost: PropTypes.bool
	}),
	accounts: PropTypes.shape({}),
	isGridLayout: PropTypes.bool,
	shouldFullyRenderCard: PropTypes.bool.isRequired
};

export default DetailsContainer;
