import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const IntersectionWrapper = ({
	observerOptions,
	shouldFullyRender,
	onIntersectCallback,
	children
}) => {
	const [hasIntersected, setHasIntersected] = useState(false);
	const ref = useRef(null);
	const canUseIntersectionObserver =
		typeof IntersectionObserver !== 'undefined';

	useEffect(() => {
		if (!hasIntersected && ref.current && canUseIntersectionObserver) {
			const node = ref.current;
			const observer = new IntersectionObserver((entries) => {
				setHasIntersected(entries[0].isIntersecting);
				// do not continue observing once it has intersected the first time
				// without this, the card will clone with the shouldFullyRenderCard prop set to false outside the viewport
				// I don't feel it is necessary to un-replace a fully rendered child currently
				if (entries[0].isIntersecting) {
					observer.unobserve(node);
					observer.disconnect();
				}
				onIntersectCallback(entries);
			}, observerOptions);
			if (!hasIntersected) {
				observer.observe(node);
			}

			return () => {
				if (node) {
					observer.unobserve(node);
				}
				observer.disconnect();
			};
		}
		return () => {};
	}, [hasIntersected, onIntersectCallback, canUseIntersectionObserver]); // eslint-disable-line react-hooks/exhaustive-deps
	// ignoring exhaustive-deps here because the observerOptions are an object that would cause this
	// useEffect to trigger every time the parent component rerenders. observerOptions should never change.
	return hasIntersected || !canUseIntersectionObserver || shouldFullyRender
		? React.cloneElement(children, {
				ref,
				shouldFullyRenderCard: true
		  })
		: React.cloneElement(children, {
				ref,
				shouldFullyRenderCard: false
		  });
};

IntersectionWrapper.propTypes = {
	observerOptions: PropTypes.shape({}),
	shouldFullyRender: PropTypes.bool,
	onIntersectCallback: PropTypes.func,
	children: PropTypes.shape({}).isRequired
};

export default IntersectionWrapper;
