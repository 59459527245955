import React from 'react';
import { useSelector } from 'react-redux';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels, useSitemap } from 'wsm-common-data';
import './LowResults.scss';
import SpotIllustration from './SpotIllustration';

const nccLinkSvg = (
	<SpotIllustration
		spotName="build-it-for-me"
		spotWidth="236"
		spotHeight="100"
	/>
);

const NewCarCustomizer = () => {
	const { isSmallScreen } = useSelector((state) => state.layout);
	const labels = useLabels();
	const bullets = [
		{
			name: 'SRP_NCC_FIRST_BULLET',
			text: labels.get('SRP_NCC_FIRST_BULLET')
		},
		{
			name: 'SRP_NCC_SECOND_BULLET',
			text: labels.get('SRP_NCC_SECOND_BULLET')
		},
		{
			name: 'SRP_NCC_THIRD_BULLET',
			text: labels.get('SRP_NCC_THIRD_BULLET')
		},
		{
			name: 'SRP_NCC_FOURTH_BULLET',
			text: labels.get('SRP_NCC_FOURTH_BULLET')
		}
	];

	const sitemap = useSitemap();
	const nccHref = sitemap.has('CAR_CUSTOMIZER')
		? sitemap.get('CAR_CUSTOMIZER')
		: '/404.htm';

	return (
		<div
			className={setClassNames([
				'srp-no-results border-top mb-0',
				'vehicle-card',
				'vehicle-card-detailed',
				'vehicle-card-horizontal',
				isSmallScreen ? 'low-result-mobile' : ''
			])}
		>
			<div className="srp-low-results-wrapper">
				<h2
					className={`${isSmallScreen ? 'display-3' : 'display-4'} 
                    mt-0 mb-5 text-center divider-centered-border-bottom divider-primary divider-2`}
				>
					{labels.get('SRP_NCC_TITLE')}
				</h2>

				<div className="srp-low-result-media-wrapper text-center mb-5">
					{nccLinkSvg}
				</div>

				<div className="text-center">
					<p
						className="mb-5"
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{
							__html: labels.get('SRP_NCC_SUB_TITLE')
						}}
					/>
				</div>

				<div className="text-center mb-5">
					<ul className="list-unstyled line-height-condensed mb-0 d-inline-block">
						{bullets.map((bullet) => (
							<li
								key={`${bullet.name}`}
								className="d-flex align-items-center"
							>
								<i
									className="ddc-icon ddc-icon-checkmark-circle-v2 pr-3 ddc-icon-size-large srp-ncc-bullet-icon"
									aria-hidden="true"
								/>
								<p
									className="text-left srp-ncc-bullet-text"
									// eslint-disable-next-line react/no-danger
									dangerouslySetInnerHTML={{
										__html: bullet.text
									}}
								/>
							</li>
						))}
					</ul>
				</div>

				<div className="text-center mb-0">
					<a className="btn btn-primary" href={nccHref}>
						{labels.get('SRP_NCC_BUTTON')}
					</a>
					<p className="mt-3 mb-0 text-muted">
						{labels.get('SRP_NCC_DISCLAIMER')}
					</p>
				</div>
			</div>
		</div>
	);
};

export default NewCarCustomizer;
