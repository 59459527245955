export const pricingMap = (pricing = {}) => {
	// TODO: remove mapping here (and in ws-detailed-pricing) and update wsm-pricing-display to use lowercase version once GVM databus is retired
	const { dprice, dPrice, eprice, ePrice, ...restPricing } = pricing;

	return {
		dPrice: dPrice || dprice,
		ePrice: ePrice || eprice,
		...restPricing
	};
};
