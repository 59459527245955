import React from 'react';
import Skeleton from 'wsm-skeleton';

export const MediaSkeletonContainerClasses = ['m-0'];

const MediaSkeleton = () => (
	<Skeleton
		skeletonClasses="mb-4"
		aspectRatio="4by3"
		dataTestId="media-skeleton"
	/>
);

export default MediaSkeleton;
