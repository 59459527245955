import React from 'react';
import PropTypes from 'prop-types';
import { useLabels, useRequestData } from 'wsm-common-data';
import { replaceContentWithJSX } from 'wsm-srp-utilities';
import { trackEvent } from 'ddc-track-event';
import { formatNumber } from 'ddc-intl';

const VehiclePackages = ({ packages, link }) => {
	const { widgetName, windowId } = useRequestData();
	const labels = useLabels();
	const packageCount = packages.length;
	const packagesRemaining = formatNumber(packageCount - 2);
	const packageLimit = packages.slice(0, 2);

	const packageLabel =
		packagesRemaining > 1
			? labels.get('MORE_INCLUDED_PACKAGES_OPTIONS')
			: labels.get('MORE_INCLUDED_PACKAGE_OPTION');
	const placeholder = {
		'{{packagesRemaining}}': packagesRemaining
	};
	const morePackagesLinkText = replaceContentWithJSX(
		packageLabel,
		placeholder
	);

	if (!packages || !packages.length) {
		return null;
	}

	return (
		<div className="vehicle-card-packages mt-md-0 mt-4">
			<ul className="list-unstyled">
				{packageLimit.map((option) => (
					<li key={option} className="d-flex align-items-start">
						<i
							aria-hidden="true"
							className="ddc-icon ddc-icon-checkmark pr-3 ddc-icon-size-large"
						/>
						{option}
					</li>
				))}
				{packageCount > 2 && (
					<a
						className="text-link-muted ddc-font-size-xsmall line-height-condensed"
						href={link}
						onClick={() => {
							trackEvent(widgetName, windowId, {
								element: 'More packages/options link',
								result: 'Navigated to VDP'
							});
						}}
						title={labels.get('VIEW_ALL_INCLUDED_PACKAGES')}
					>
						{morePackagesLinkText}
					</a>
				)}
			</ul>
		</div>
	);
};

VehiclePackages.propTypes = {
	packages: PropTypes.arrayOf(PropTypes.string),
	link: PropTypes.string.isRequired
};

VehiclePackages.defaultProps = {
	packages: []
};

export default VehiclePackages;
