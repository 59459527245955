import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { trackEvent } from 'ddc-track-event';
import { usePrefs, useLabels, useRequestData } from 'ws-scripts/modules/common';
import { setClassNames } from 'ddc-classnames-js';
import { useWindowDimension } from 'wsm-srp-utilities';
import { setModalSettings } from '../features/modalSettings';
import DDCPlayerVideoComponent from './DDCPlayerVideoComponent';
import IFrameVideoComponent from './IFrameVideoComponent';
import { calculateVideoSize } from '../utilities/videoSize';

const MediaModalContainer = () => {
	const { viewportWidth, viewportHeight } = useWindowDimension();
	const { deviceType, windowId, widgetName } = useRequestData();
	const labels = useLabels();
	const { videoModalDetailsButtonLabel, videoModalPhoneLabel } = usePrefs();
	const dispatch = useDispatch();
	const modalSettings = useSelector((state) => state.modalSettings);
	const modalContainerRef = useRef(null);

	const { uuid, videos } = modalSettings;
	const [video] = videos;

	const { isModalOpen, title, link, phone = '', accountId } = modalSettings;

	useEffect(() => {
		modalContainerRef.current.focus();
	}, [isModalOpen]);

	const videoWidth = calculateVideoSize(
		viewportWidth,
		viewportHeight,
		deviceType
	);
	const closeButtonFn = () => {
		dispatch(
			setModalSettings({
				isModalOpen: false
			})
		);
	};

	const headerClassnames = [
		'media-modal-header',
		'd-flex',
		'align-items-center',
		'justify-content-center',
		'text-center'
	];

	const containerClasses = [
		'media-modal',
		'absolute-top',
		'd-none',
		'position-fixed',
		'vw-100',
		'vh-100',
		'overflow-hidden',
		'shadow-none',
		...(isModalOpen ? ['d-block'] : [])
	];

	const backgroundClassnames = [
		'media-modal-bg',
		'absolute-top',
		'w-100',
		'h-100',
		deviceType === 'MOBILE' ? 'opacity-100' : 'opacity-75'
	];

	const phoneClassnames = ['pl-0', 'pr-0', 'btn', 'btn-link'];

	const footerClassnames = [
		'p-4',
		'd-flex',
		'justify-content-between',
		'align-items-center'
	];

	const closeButtonClassNames = [
		'media-modal-button',
		'text-decoration-none',
		'absolute-right',
		'btn',
		'btn-link'
	];
	const closeButtonIconClassNames = [
		'ddc-icon',
		deviceType === 'DESKTOP'
			? 'ddc-icon-cancel-circle'
			: 'ddc-icon-remove2',
		'ddc-icon-size-xlarge'
	];

	let videoComponent;
	if (video && video.provider && video.provider === 'DCPAIS') {
		videoComponent = (
			<IFrameVideoComponent video={video} accountId={accountId} />
		);
	} else {
		videoComponent = (
			<DDCPlayerVideoComponent
				video={video}
				uuid={uuid}
				accountId={accountId}
				videoWidth={videoWidth}
			/>
		);
	}

	const closeButton = (
		<button
			type="button"
			aria-label={labels.get('CLOSE')}
			className={setClassNames(closeButtonClassNames)}
			onClick={closeButtonFn}
		>
			<i
				className={setClassNames(closeButtonIconClassNames)}
				aria-hidden="true"
			/>
		</button>
	);

	return (
		<div
			className={setClassNames(containerClasses)}
			tabIndex="-1"
			role="dialog"
			aria-hidden="true"
			ref={modalContainerRef}
		>
			<div className={setClassNames(backgroundClassnames)} />
			{deviceType === 'MOBILE' && (
				<React.Fragment>
					{closeButton}
					{isModalOpen && videoComponent}
				</React.Fragment>
			)}

			{deviceType === 'DESKTOP' && (
				<div className="media-modal-container media-modal-center-block position-absolute">
					<div className={setClassNames(headerClassnames)}>
						{closeButton}
						<span className="h3 font-weight-bold">{title}</span>
					</div>
					<hr className="m-0 mb-4" />
					{isModalOpen && videoComponent}
					<div className={setClassNames(footerClassnames)}>
						{phone && (
							<a
								className={setClassNames(phoneClassnames)}
								href={`tel:${phone}`}
								onClick={closeButtonFn}
							>
								<span className="text-uppercase font-weight-bold">
									{labels.get(videoModalPhoneLabel)}{' '}
								</span>
								<span>{phone}</span>
							</a>
						)}
						<a
							className="btn btn-default"
							href={link}
							onClick={() => {
								closeButtonFn();
								trackEvent(widgetName, windowId, {
									element: 'View details link',
									result: 'Navigated to VDP',
									widgetState: 'video modal'
								});
							}}
						>
							{labels.get(videoModalDetailsButtonLabel)}
						</a>
					</div>
				</div>
			)}
		</div>
	);
};

MediaModalContainer.propTypes = {
	options: PropTypes.shape({}),
	video: PropTypes.shape({
		provider: PropTypes.string
	})
};

MediaModalContainer.defaultProps = {
	options: {},
	video: null
};

export default MediaModalContainer;
